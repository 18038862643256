import React, { useEffect } from "react";
import Layout, { PageWrapper } from "../components/layout";
import { PageSection } from "../styles/index/_components";
import "twin.macro";
import Mail from "../images/vectors/undraw_Mailbox_re_dvds.svg";
import Title from "../components/text/title";
import { FormStyled, MailWrapperStyled } from "../components/_components";
import { Helmet } from "react-helmet";
import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";
import { keyframes } from "@emotion/react";

const additionalOptions = {};

const myStyles = [
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [{ visibility: "off" }],
  },
];

const loader = new Loader({
  apiKey: process.env.API_KEY_MAPS,
  version: "weekly",
  ...additionalOptions,
});

const Kontakt = () => {
  useEffect(() => {
    loader.load().then(() => {
      var map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 54.384779599760165, lng: 18.592661239360634 },
        zoom: 18,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        styles: myStyles,
      });
      const marker = new window.google.maps.Marker({
        position: { lat: 54.384779599760165, lng: 18.592661239360634 },
        map: map,
      });
    });
  }, []);

  const handleSubmit = (e) => {
    const target = e.target;
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(process.env.API_KEY_CAPTCHA, { action: "submit" })
        .then(function (token) {
          axios
            .post("/api/contact/contactForm", {
              token: token,
              form: {
                name: target.name.value,
                email: target.email.value,
                person: target.person.value,
                problem: target.problem.value,
              },
            })
            .then((res) => {
              target.name.value = "";
              target.email.value = "";
              target.problem.value = "";
              buttonHolderRef.current.disabled = true;
              buttonHolderRef.current.style.background = "lightgreen";
              buttonHolderRef.current.innerText = "Email wysłano poprawnie!";
            });
        });
    });
  };

  const buttonHolderRef = React.createRef();

  return (
    <Layout>
      <PageSection>
        <PageWrapper>
          <div tw="container text-center">
            <Title>Skontaktuj się z nami!</Title>
            <div tw="grid grid-flow-row md:grid-cols-2 mt-6">
              <MailWrapperStyled>
                <Mail />
              </MailWrapperStyled>
              <FormStyled
                tw="flex flex-col justify-between"
                id="problemForm"
                onSubmit={(e) => handleSubmit(e)}
              >
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Imię oraz Nazwisko"
                  required={true}
                />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required={true}
                />
                <select name="person" id="person" required={true}>
                  <option label=" " hidden></option>
                  <option value="justynabarton@libere.pl">Justyna</option>
                  <option value="juliannagraczyk@libere.pl">Julianna</option>
                  <option value="maciejschmidt@libere.pl">Maciej</option>
                </select>
                <textarea
                  name="problem"
                  id="problem"
                  placeholder="Opis problemu"
                  required={true}
                />
                <div tw="flex items-center justify-center text-two pb-3">
                  <button
                    tw="text-2xl border-2 bg-three-50 border-two text-four"
                    type="submit"
                    ref={buttonHolderRef}
                  >
                    Wyślij
                  </button>
                </div>
              </FormStyled>
            </div>
          </div>
          <div tw="container text-center flex flex-col xl:flex-row xl:items-center">
            <div>
              <div tw="flex flex-col p-6 text-2xl">
                <div tw="text-3xl font-bold pb-3">Libere</div>
                <div tw="whitespace-nowrap">
                  <div>Aleja Grunwaldzka 184</div>
                  <div>Gdańsk, 80-266</div>
                </div>
              </div>
            </div>
            <div id="map" style={{ width: "100%", height: 500 }}></div>
          </div>
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default Kontakt;
